$(document).ready(function(){

    $('.brand-checkbox').click(function(){

        var checkbox = $(this).parent().parent();

        checkbox.next('select').fadeToggle();

        checkbox.toggleClass('checked');

    });

});

$(document).ready(function(){

    $('#bite-form').bootstrapValidator({
        trigger : 'blur',
        feedbackIcons: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'

        },
        fields: {
            bite_card_number :{
                validators :{
                    notEmpty :{
                        message: 'Please enter the long card number'
                    },
                    stringLength :{
                        min: 16,
                        max: 16,
                        message: 'The card number must be 16 digits'
                    },
                    numeric :{
                        message : 'The card number can only contain numbers'
                    }
                }
            },
            firstname: {
                validators: {
                    notEmpty :{
                        message: 'Your first name is required'
                    }
                }
            },
            lastname:{
                validators:{
                    notEmpty :{
                        message: 'Youe last name is requiured'
                    }
                }
            },
            address_1 :{
                validators :{
                    notEmpty :{
                        message: 'Please enter the first line of your address'
                    }
                }
            },
            city: {
                validators :{
                    notEmpty :{
                        message: 'Please enter the City or Town'
                    }
                }
            },
            county: {
                validators :{
                    notEmpty: {
                        message: 'Please enter the county'
                    }
                }
            },
            mobile :{
                validators :{
                    phone:{
                        country: 'GB',
                        message: 'The mobile number is invalid'
                    }
                }
            },
            email: {
                validators :{
                    notEmpty :{
                        message: 'Please enter your email address'
                    },
                    emailAddress :{
                        message: 'The email address is not valid'
                    }
                }
            },
            password: {
                validators: {
                    notEmpty :{
                        message : 'Please enter a password'
                    }
                }
            },
            password_confirmation :{
                validators :{
                    identical :{
                        field: 'password',
                        message: 'The passwords entered do not match'
                    },
                    notEmpty:{
                        message: 'Please confirm your password'
                    }

                }
            },
            age_confirmed :{
                validators :{
                    notEmpty:{
                        message: 'Please confirm your age'
                    }
                }
            },
            marketing_means:{
                validators:{
                    notEmpty:{
                        message: 'Please select how you heard about us'
                    }
                }
            },
            terms_agreed:{
                validators:{
                    notEmpty:{
                        message: 'Please confirm'
                    }
                }
            }
        }
    })

    $('#login-form').bootstrapValidator({
        trigger: 'blur',
        feedbackIcons: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'

        },
        fields :{
            email:{
                validators :{
                    notEmpty :{
                        message: 'Please enter your email address'
                    },
                    emailAddress :{
                        message: 'The email address is invalid'
                    }
                }
            },
            password :{
                validators: {
                    notEmpty :{
                        message : 'Please enter your password'
                    }
                }
            }
        }
    })

    $('#contact-form').bootstrapValidator({
        trigger: 'blur',
        feedbackIcons :{
            valid: 'glyphicon glyphicon-ok',
            invliad: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        fields:{
            email:{
                validators:{
                    notEmpty:{
                        message: 'Please enter your email address'
                    },
                    emailAddress: {
                        message: 'The email address is invalid'
                    }
                }
            }
        }
    })

    $('#profile-form').bootstrapValidator({
        trigger: 'blur',
        feedbackIcons :{
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        fields:{
            firstname :{
                validators: {
                    notEmpty: {
                        message: 'Your first name is required'
                    }
                }
            },
            lastname :{
                validators :{
                    notEmpty: {
                        message: 'Your last name is required'
                    }
                }
            },
            address_1:{
                validators:{
                    notEmpty:{
                        message: 'Please enter the first line of your address'
                    }
                }
            },
            city:{
                validators: {
                    notEmpty:{
                        message: 'Please enter your City or Town'
                    }
                }
            },
            county:{
                validators:{
                    notEmpty:{
                        message: 'Please enter the county'
                    }
                }
            },
            mobile :{
                validators :{
                    phone:{
                        country: 'GB',
                        message: 'The mobile number is invalid'
                    }
                }
            },
            email:{
                validators: {
                    notEmpty:{
                        message: 'Please enter your email address'
                    },
                    emailAddress:{
                        message: 'The email address is invalid'
                    }
                }
            },
            age_confirmed :{
                validators :{
                    notEmpty:{
                        message: 'Please confirm your age'
                    }
                }
            }
        }
    })

    $('#reset-password-form').bootstrapValidator({
        trigger: 'blur',
        feedbackIcons : {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        fields:{
            password: {
                validators:{
                    notEmpty:{
                        message: 'Please enter a new password here'
                    }
                }
            },
            password_confirmation:{
                validators:{
                    notEmpty:{
                        message: 'Please confirm the new password'
                    },
                    identical:{
                        field: 'password',
                        message: 'The passwords do not match'
                    }
                }
            }
        }
    })
});